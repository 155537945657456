import * as fb from "@/firebase";
// import { auth } from "../../firebase";
// import router from "../router/index";
// import { db } from "@/firebase";
import {
  GET_TIME_SHEETS,
  GET_PAYROLL_TYPES,
  UPDATE_ACCOUNTING_CONFIG,
} from "../action-types";
import {
  SET_TIME_SHEETS,
  SET_BILLING_TYPES,
  SET_LOADING,
} from "../mutation-types";
export default {
  namespaced: true,

  state: {
    timeSheets: [],
    payrollTypes: [],
    loading: false,
  },

  mutations: {
    [SET_TIME_SHEETS](state, payload) {
      state.timeSheets = payload;
    },
    [SET_BILLING_TYPES](state, payload) {
      state.payrollTypes = payload;
    },
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },
    // [MUTATION_NAME](state, payload) {
    //   state.var = payload;
    // },
  },

  actions: {
    async [GET_TIME_SHEETS]({ commit }, payload) {
      try {
        const data = [];
        const querySnapshot = await fb.organizationCollection
          .doc(payload.organizationId)
          .collection("timeSheets")
          .get();

        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        commit(SET_TIME_SHEETS, data);
        return querySnapshot;
      } catch (error) {
        console.log("Error getting document:", error);
        throw error;
      }
    },

    async [GET_PAYROLL_TYPES]({ commit }, payload) {
      try {
        const data = [];
        const querySnapshot = await fb.organizationCollection
          .doc(payload.organizationId)
          .collection("payrollTypes")
          .get();

        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        data.sort((a, b) => parseFloat(a.sortKey) - parseFloat(b.sortKey));
        commit(SET_BILLING_TYPES, data);
        return querySnapshot;
      } catch (error) {
        console.log("Error getting document:", error);
        throw error;
      }
    },

    async [UPDATE_ACCOUNTING_CONFIG]({ commit }, payload) {
      let updateData = {};
      if (payload.type === "onlyTemplateConfig") {
        updateData = {
          "config.accounting.timeSheets.titleTemplates":
            payload.timeSheets.titleTemplates,
        };
      }
      try {
        await fb.organizationCollection.doc(payload.organizationId).update({
          ...updateData,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        });
        commit(SET_LOADING, false);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    // [ACTION_NAME]({ commit }, payload) {
    //   ... SOME MAGIC HAPPENS HERE ...
    //   commit(MUTATION_NAME, withSomeData);
    // },
  },

  getters: {},
};
